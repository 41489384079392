<template>
  <v-list-item :key="item.name" class="px-1">
    <v-badge v-if="false" bordered bottom color="green" dot offset-x="22" offset-y="26">
      <v-list-item-avatar>
        <v-img :src="item.avatar"></v-img>
      </v-list-item-avatar>
    </v-badge>
    <template>
      <v-list-item-content :class="{ 'font-weight-bold': item.message_count_unviewed }" style="box-sizing: content-box">
        <div style="display: flex; justify-content: space-between" class="d-flex list-item-message">
          <div style="font-size: 17px" class="list-item-message">{{ item.name }}</div>
          <div style="font-size: 12px" :class="{ 'unviewed-group': item.message_count_unviewed }">{{ getDate(item.createdon) }}</div>
        </div>
        <div v-if="item.chat_name" style="margin-top: 2px; justify-content: space-between; color: green" class="d-flex list-item-message">
          <div class="list-item-message" style="font-size: 12px">{{ item.chat_name }}</div>
        </div>
        <div style="margin-top: 2px; justify-content: space-between" class="d-flex list-item-message">
          <div class="list-item-message">
            <span>{{ item.content }}</span>
          </div>
          <span class="list-item-count-unviewed" v-if="item.message_count_unviewed" :value="item.message_count_unviewed" :content="1"
            >{{ item.message_count_unviewed }}
          </span>
        </div>
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
export default {
  components: {},
  props: {
    item: Object,
  },
  data() {
    return {
      selected: null,
      lazy: true,
    };
  },
  computed: {},
  methods: {
    getDate(d) {
      if (!d) return null;
      return new Date(d).toLocaleDateString("ru-RU");
    },
  },
};
</script>
<style lang="scss" scoped>
.list-item-message {
  overflow: hidden;
  text-overflow: ellipsis;
  // text-rendering: optimizelegibility;
  white-space: nowrap;
}
.unviewed-group {
  color: #4caf50;
}
.list-item-count-unviewed {
  font-size: 0.75rem;
  border-top-left-radius: 1.1em;
  border-top-right-radius: 1.1em;
  border-bottom-right-radius: 1.1em;
  border-bottom-left-radius: 1.1em;
  background-color: #4caf50;
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.3em;
  padding-bottom: 0.2em;
  min-width: 0.9em;
  min-height: 1em;
  color: white;
  vertical-align: top;
  text-align: center;
}
</style>
